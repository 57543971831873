<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <v-text-field
                class="ma-0"
                :label="$store.getters.translate('name')"
                v-model="record.name">
              <template #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
            </v-text-field>
            <v-select
                class="ma-0"
                :label="$store.getters.translate('model_type')"
                v-model="record.model_type"
                validate-on-blur
                :items="$lodash.sortBy(models, 'text')">
              <template #label>{{ $store.getters.translate('model_type') }}<span class="red--text"><strong>*</strong></span></template>
            </v-select>
            <v-select
                class="ma-0"
                :label="$store.getters.translate('linked_model')"
                v-model="record.linked_model"
                validate-on-blur
                :items="$lodash.sortBy(models, 'text')"
                clearable/>
            <v-select
                class="ma-0"
                :label="$store.getters.translate('event')"
                v-model="record.event"
                validate-on-blur
                :items="getEvents">
              <template #label>{{ $store.getters.translate('event') }}<span class="red--text"><strong>*</strong></span></template>
            </v-select>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: { BaseModal, BaseDraggableModal },
  props: ["modal_name"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("workflows"),
        name: "workflows",
        model: "workflow",
      },
      record: {
        name: null,
      },
      loading: false,
      models: {},
    };
  },
  created() {
    this.models = helpFunctions.models;
  },
  methods: {
    ...helpFunctions.modal_functions,
    async save() {
      if(!this.loading) {
        let usual_fields_required = [{name: 'name', mandatory: true}, {name: 'model_type', mandatory: true}, {name: 'event', mandatory: true}];
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, usual_fields_required)) {
          this.loading = true;
          let record_id = await helpFunctions.createModel(this.$toasted, this.page.name, this.record);
          if (record_id) {
            await this.$router.push("/" + this.page.name + "/" + record_id);
            this.reset();
          }
          this.loading = false;
        }
      }
    },
    reset() {
      this.record = {
        name: null
      };
      this.closeModal(this.modal_name);
    },
  },
  computed: {
    getEvents() {
      return this.record.model_type ? this.$lodash.sortBy(this.models.find((model) => model.value === this.record.model_type).children, "text") : [];
    },
  },
};
</script>
